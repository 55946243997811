:root{
  --primary-color: #1e1138 !important;
  --secondary-color: #8456e6 !important;
  --black-color: #060506 !important;
  --green-color: #28a745;
  --grey-color: #C3C8C9 !important;
  --grey-color-link: #787D7E;
  --light-color: #F0F2F3;
  --blue-color: #006ba3;
  --btn-primary: #1e1138 !important;
  --btn-secondary: #8456e6 !important;
}

$theme-colors: (
  "primary": #1e1138,
  "secondary": #8456e6,
  "black": #060506,
  "green": #28a745,
  "grey": #C3C8C9,
  "grey-link": #787D7E,
  "grey-light": #A0A5A6,
  "light": #EBEDF0,
  "blue": #006ba3,
  "white": #fff
);
