/* Importing Bootstrap SCSS file. */
@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

#country-search-box {
  color: var(--dark-color) !important;
  border: 0 !important;
  padding: 0.75em !important;
  height: auto !important;

  &:focus {
    outline: none !important;
  }
}

.container-xxl {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-menu.country-dropdown.show {
  width: 256px;
  border: 0px;
  margin-top: 6px;
  margin-left: -16px;
  border-radius: 10px;

  .search-container {
    margin-bottom: 3px !important;
  }

  ::-webkit-scrollbar {
    width: 10px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--secondary-color) !important;
    border-radius: 9px;
  }
}

.iti__selected-flag {
  padding: 0px !important;
}

.iti__country-list {
  left: -1px !important;
  border-radius: 10px;
}

.iti__country {
  padding: 9px 10px !important;
  outline: none;

  &:hover {
    color: white;
    border-radius: 9px !important;
    background-color: var(--secondary-color) !important;
  }
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type="tel"] {
  padding-left: 60px !important;
}

.iti__arrow {
  margin-left: 6px !important;
  width: 0 !important;
  height: 0 !important;
  border-left: 3px solid transparent !important;
  border-right: 3px solid transparent !important;
  border-top: 4px solid var(--secondary-color) !important;
}

.dropdown-toggle {
  &:hover {
    background-color: transparent !important;
  }
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  left: 16px !important;
}

.ng-dropdown-panel-items {
  height: 10em !important;
}

.dropdown-menu.country-dropdown.show {
  background: #ebedf0;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

.ng-dropdown-panel.ng-select-bottom {
  border-radius: 12px !important;
  opacity: 1 !important;
  border: 0 !important;
  box-shadow: rgb(219 221 221) 2px 2px 10px !important;
}

.ng-select.custom .ng-select-container {
  background: #ebedf0;
  min-height: 48px;
  border: none;
  border-radius: 10px;
}

.iti__country-list {
  box-shadow: none !important;
}

input#country-search-box {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  background: #ebedf0;

  @include border-radius($input-border-radius, 0);
  @include box-shadow($input-box-shadow);
  @include form-control-focus($ignore-warning: true);

  &::-ms-expand {
    border: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    opacity: 1;
  }
}

input#country-search-box:focus {
  color: #495057;
  background-color: #ebedf0;
  border-color: #a5a8ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(37 45 255 / 25%);
}

.nav-tabs {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  position: relative;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: 1px solid #252dff;
  border-radius: 9px !important;
  line-height: 30px;
  width: 119px;
}

.nav-tabs .nav-link {
  border: 1px solid #252dff;
  border-radius: 9px !important;
  line-height: 30px;
  width: 119px;
  margin-right: 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: var(--primary-color);
  color: var(--light-color);
  font-weight: 700;
  line-height: 30px;
  border-radius: 9px;
  width: 119px;
}

button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.ng-select.custom {
  border-radius: 10px;
  background: #ebedf0;
  border-radius: 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #252dff !important;
  color: #fff !important;
  border-radius: 5px;
}

.ng-select .ng-select-container {
  color: #c3c8c9 !important;
  background-color: #ebedf0 !important ;
  border: 1px solid #ebedf0 !important;
  min-height: 45px !important;
  border-radius: 10px !important;
}

.ng-select.custom.ng-select-opened > .ng-select-container {
  background: #ebedf0;
}

.ng-select.custom .ng-select-container .ng-input {
  padding: 0.6em 1em;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--grey-color) !important;
  font-size: 18px;
  line-height: 24px;
  font-weight: 100;
}

.ng-select.ng-select-single .ng-select-container {
  background-color: #f1f2f4 !important;
  border: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  border: 0;
  border-radius: 10px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05), 0px 20px 50px rgba(0, 0, 0, 0.15);
}

.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background-color: var(--primary-color) !important;
  color: #ffff !important;
  width: 169px;
  cursor: pointer;
}

.ck.ck-list__item .ck-button.ck-on {
  background-color: transparent !important;
  width: 169px;
  color: var(--secondary-color) !important;
  font-weight: 400 !important;
}

.ck.ck-list__item .ck-button {
  color: var(--secondary-color) !important;
  font-weight: 400 !important;
}

.ck-rounded-corners
  .ck.ck-dropdown
  .ck-dropdown__panel
  .ck-list
  .ck-list__item:last-child
  .ck-button {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  width: 169px;
}

.ck-rounded-corners
  .ck.ck-dropdown
  .ck-dropdown__panel
  .ck-list
  .ck-list__item:first-child
  .ck-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-list {
  border-radius: 9px !important;
  width: 10.6em;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  p {
    color: var(--secondary-color) !important;
  }
}

.ck-rounded-corners .ck.ck-toolbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: var(--light-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: 0;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: var(--light-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background-color: var(--light-color) !important;
}

@font-face {
  font-family: "Montserrat";
  // font-weight: 700;
  src: url(./assets/fonts/Montserrat-Bold.otf);
}
@font-face {
  font-family: "Montserrat-regular";
  // font-weight: 700;
  src: url(./assets/fonts/Montserrat-Regular.otf);
}
@font-face {
  font-family: "Roboto";
  // font-weight: 700;
  src: url(./assets/fonts/Roboto-Thin.otf);
}
@font-face {
  font-family: "FuturaLT";
  // font-weight: 700;
  src: url(./assets/fonts/FuturaLT-Bold.otf);
}
// @font-face {
//   font-family: "mark";
//   font-weight: 600;
//   src: url(./assets/fonts/MarkBold.otf);
// }
// @font-face {
//   font-family: "mark";
//   font-weight: 300;

//   src: url(./assets/fonts/MarkLight.otf);
// }
// @font-face {
//   font-family: "mark";
//   font-weight: 400;
//   src: url(./assets/fonts/MarkBook.otf);
// }
body {
  font-family: "Roboto";

  h1 {
    font-family: "Montserrat";
    font-size: 2.2em;
  }

  h2 {
    font-family: "Montserrat";
    font-size: 27px;
    color: var(--primary-color);
  }

  h3 {
    font-family: "Montserrat";
    color: var(--primary-color);
    font-size: 24px;
    line-height: 30px;
  }

  h4{
    font-family: "Montserrat";
  }

  h5 {
    font-size: 1.1em;
    line-height: 30px;
  }

  p {
    line-height: 27px;
    color: var(--grey-color);
    font-size: 15px;
  }

  a {
    color: var(--primary-color);
    font-size: 0.9em;
  }

  .btn {
    font-family: "Montserrat-regular";
    border-radius: 1em;
    font-weight: normal;
    padding: 0.33em 1.5em;
  }

  .btn-light{
    font-family: "Montserrat-regular";
    border-radius: 0px;
  }

  .btn-lg {
    font-size: 1em;
    padding: 0.75em 1.5em;
  }

  .btn:focus,
  .btn.focus {
    box-shadow: none;
  }

  .card {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05),
      0px 20px 50px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    border: none;
  }

  .card-header {
    background-color: transparent;
    padding: 1.75em;
    border-color: #ebedf0;

    .card-title{
    font-family: "Montserrat-regular" !important;
    }
  }

  .card-body {
    background-color: transparent;
    padding: 1.75em;
    & > hr {
      margin: 1.5em -1.75em;
    }
  }

  .top-0 {
    top: 0;
  }

  .bottom-0 {
    bottom: 0;
  }

  .left-0 {
    left: 0;
  }

  .right-0 {
    right: 0;
  }

  .artworks-bg {
    background-image: url("./assets/img/bg-imgs.png");
    background-position: center;
    background-size: cover;
  }

  .line-art {
    height: 14em;
    border-radius: 12px !important;
    object-fit: cover;
  }

  .imgs-collections {
    height: 12.5em;
    border-radius: 10px;
    object-fit: cover;
  }

  .bg-azul-escuro {
    color: #ffffff;
    background-color: #071333;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 800px;
  }

  .bg-azul-forte {
    background-color: #252dff;
    height: 800px;
  }

  .bg-cinza {
    background-color: #c3c8c9;
    height: 600px;
  }

  .bg-branco {
    background-color: #ebedf0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 1000px;
  }

  .bg-azul-escuro-2 {
    color: #ffffff;
    background-color: #071333;
    height: 1000px;
    padding: 0px auto;
  }

  .contador {
    width: 3.5em;
    height: 3.5em;
    border-radius: 10px;
  }
}

.bg-light {
  background-color: var(--light-color) !important;
  padding: 1.43em 1em;
  border-radius: 10px;
  height: 2.8em;
  border: none;
}

.form-control,
.form-control:active,
.form-control:focus {
  color: var(--dark-color);
  background-color: var(--grey-color);
  border: 0;
  border-radius: 10px;
  padding: 0.75em;
  height: auto;
}

::-webkit-input-placeholder {
  color: #c3c8c9 !important;
}

.tabs {
  .tab {
    a {
      color: var(--grey-color);
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: var(--primary-color);
        font-weight: bold !important;
      }
    }
  }
}

.contract-modal .modal-dialog {
  width: 600px;
  max-width: 100%;
}

.two-factor-modal .modal-dialog {
  width: 600px;
  max-width: 100%;
  .modal-content {
    border-radius: 9px;
  }
}

.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
}

.drop-avatar {
  .ngx-file-drop__drop-zone {
    border: 2px solid lightgrey !important;
    border-radius: 30px !important;
    height: 106px !important;
  }
}

@media (min-width: 576px) {
  .modal-edit-artwork .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }

  .share-embed .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 615px) {
  .two-factor-modal .modal-dialog {
    width: 500px;
    max-width: 100%;
    margin: 0;
  }
}
.bg-info {
  background-color: var(--primary-color) !important;
}

.accordion > .card > .card-header {
  .btn .btn-link .collapsed {
    font-size: 1.5rem !important;
    color: var(--secondary-color) !important;
    font-weight: bold !important;
  }
}

.accordion {
  .card {
    display: contents;
    .card-body {
      display: contents;
      padding: 1.75em 0;
    }
    .card-header {
      padding: 15px 0 10px;

      .btn-link {
        font-size: 1.5rem;
        text-align: left;
        width: 100%;
        color: var(--secondary-color);
        font-weight: bold;
        text-decoration: none;
        padding: 0;
        margin: -1.3em 0em 0em;
      }
      .btn-link[aria-expanded="false"] {
        background-image: url(/assets/icons/arrow-down-border-blue.png);
        background-repeat: no-repeat;
        background-position: right;
      }
      .btn-link[aria-expanded="true"] {
        background-image: url(/assets/icons/arrow-up-border-blue.png);
        background-repeat: no-repeat;
        background-position: right;

        .card-header {
          padding: 15px 0;
          margin: 0em 0 3em;
        }
      }
    }
  }
}
